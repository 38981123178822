import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import TemplatesLanding from "../../components/pages/templates/TemplatesLanding";
import TemplatesSection from "../../components/pages/templates/TemplatesSection";
import Content from "../../components/layout/Content/Content";
import Shoutout from "../../components/pages/signup/Shoutout";

import productivityAppImage from "../../components/pages/home/HomeExamples/productivity_app_cover.png";
import mobileWireframeImage from "../../components/pages/home/HomeExamples/mobile_wireframe_cover.png";
import travelAppImage from "../../components/pages/home/HomeExamples/travel_app_cover.png";
import quickBiteMobileImage from "../../components/pages/home/HomeExamples/food_delivery_mobile_app_cover.png";
import basketImage from "../../components/pages/home/HomeExamples/basket_cover.png";
import musicMobileAppImage from "../../components/pages/home/HomeExamples/music_mobile_app_cover.png";

import gmdwaImage from "../../components/pages/home/HomeExamples/GMDWA_cover.png";
import carbonImage from "../../components/pages/home/HomeExamples/IBM_Carbon_cover.png";
import quickBiteImage from "../../components/pages/home/HomeExamples/food-delivery-web-app-cover.png";
import hrAppImage from "../../components/pages/home/HomeExamples/hr_app_cover.png";
import pitchDeckImage from "../../components/pages/home/HomeExamples/online_interactive_pitch_deck_cover.png";
import onlineBankingImage from "../../components/pages/home/HomeExamples/online_banking_web_app_cover.png";

import landoImage from "../../components/pages/home/HomeExamples/lando_cover.png";
import webWireframeImage from "../../components/pages/home/HomeExamples/web_wireframe_cover.png";
import eShopImage from "../../components/pages/home/HomeExamples/e_shop_cover.png";
import estateImage from "../../components/pages/home/HomeExamples/estate_cover.png";
import web3Image from "../../components/pages/home/HomeExamples/web3_crypto_website_cover.png";
import petShopImage from "../../components/pages/home/HomeExamples/pet_web_store_cover.png";

import bankAppTabletImage from "../../components/pages/home/HomeExamples/bank-tablet-app-cover.png";
import eCommerceTabletImage from "../../components/pages/home/HomeExamples/e-commerce-tablet-app-cover.png";
import landoTabletImage from "../../components/pages/home/HomeExamples/lando-tablet-cover.png";
import booktabImage from "../../components/pages/home/HomeExamples/book-tab-app-cover.png";
import newsfeedtabImage from "../../components/pages/home/HomeExamples/news-tab-app-cover.png";
import learningtabImage from "../../components/pages/home/HomeExamples/learning-tab-app-cover.png";

import navImage from "../../components/pages/templates/componentTemplates/nav.png";
import socialImage from "../../components/pages/templates/componentTemplates/social.png";
import listImage from "../../components/pages/templates/componentTemplates/list.png";
import formImage from "../../components/pages/templates/componentTemplates/form.png";
import footerImage from "../../components/pages/templates/componentTemplates/footer.png";
import blogImage from "../../components/pages/templates/componentTemplates/blog.png";

const Templates = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Meta
        title="UI Templates | UI Design Templates | Uizard"
        description="Never start from scratch again. Bring your ideas to life using our UI design templates. Explore the range now and start your project for free."
        url="/templates/"
      />

      <TemplatesLanding
        imageA={data.comparisonScreensImageA.childImageSharp}
        imageB={data.comparisonScreensImageB.childImageSharp}
        headline="UI design templates"
        description="Bring your ideas to life with our pre-made UI design templates. Whether you are looking for an <a:https://uizard.io/templates/mobile-app-templates/>app design template</a> or something for web, our UI templates come with all the must-have components baked in!"
      />

      <TemplatesSection
        title="Mobile app design templates"
        description="Fast track your design flow with one of our mobile app UI templates, perfect for your android or iOS mobile app design project."
        linkPage="/templates/mobile-app-templates/"
        thumbnails={[
          {
            image: productivityAppImage,
            alt: "productiviy mobile app project image",
            title: "Productivity mobile app",
            link: "/templates/mobile-app-templates/productivity-mobile-app/",
          },
          {
            image: mobileWireframeImage,
            alt: "mobile wireframe example project image",
            title: "Mobile app wireframe",
            link: "/templates/mobile-app-templates/mobile-app-wireframe/",
          },
          {
            image: travelAppImage,
            alt: "travel mobile app design template",
            title: "Travel mobile app",
            link: "/templates/mobile-app-templates/travel-mobile-app/",
          },
          {
            image: quickBiteMobileImage,
            alt: "food delivery mobile app project image",
            title: "Food delivery mobile app",
            link: "/templates/mobile-app-templates/mobile-food-delivery-app/",
          },
          {
            image: basketImage,
            alt: "clothing shopping app example project image",
            title: "Clothes shopping mobile app",
            link: "/templates/mobile-app-templates/sport-clothing-shopping-app/",
          },
          {
            image: musicMobileAppImage,
            alt: "music streaming mobile app design template",
            title: "Music streaming mobile app",
            link: "/templates/mobile-app-templates/music-streaming-app/",
          },
        ]}
      />

      <TemplatesSection
        title="Website design templates"
        description="Designing your very own website but don't have time to work on the finer detail? Our website UI templates are fully customizable and just a click away."
        linkPage="/templates/website-templates/"
        thumbnails={[
          {
            image: landoImage,
            alt: "Startup landing page Lando example project image",
            title: "Startup website landing page",
            link: "/templates/website-templates/startup-landing-page/",
          },
          {
            image: webWireframeImage,
            alt: "Website wireframe example project image",
            title: "Website wireframe",
            link: "/templates/website-templates/website-wireframe/",
          },
          {
            image: eShopImage,
            alt: "E-shop example project image",
            title: "E-commerce website",
            link: "/templates/website-templates/e-commerce-website/",
          },
          {
            image: estateImage,
            alt: "Real Estate website example project image",
            title: "Real estate website",
            link: "/templates/website-templates/real-estate-website/",
          },
          {
            image: web3Image,
            alt: "Web3 crypto website project image",
            title: "Web3 crypto website",
            link: "/templates/website-templates/web3-crypto-website/",
          },
          {
            image: petShopImage,
            alt: "Online pet shop project image",
            title: "Pet shop website",
            link: "/templates/website-templates/pet-store/",
          },
        ]}
      />

      <TemplatesSection
        title="Web app design templates"
        description="The perfect place to start for designing your very own web app. Simply sign up to Uizard and let our pre-made web app UI templates do the rest."
        linkPage="/templates/web-app-templates/"
        thumbnails={[
          {
            image: gmdwaImage,
            alt: "Google Material Design Web App project image",
            title: "Google Material Design web app",
            link: "/templates/web-app-templates/google-material-design-web-app/",
          },
          {
            image: carbonImage,
            alt: "IBM Carbon dashboard web app project image",
            title: "IBM Carbon cloud dashboard",
            link: "/templates/web-app-templates/ibm-carbon-cloud-dashboard-web-app/",
          },
          {
            image: quickBiteImage,
            alt: "Food delivery web app project image",
            title: "Food delivery web app",
            link: "/templates/web-app-templates/online-food-delivery/",
          },
          {
            image: hrAppImage,
            alt: "Human resources web app project image",
            title: "Human resources web app",
            link: "/templates/web-app-templates/hr-web-app/",
          },
          {
            image: pitchDeckImage,
            alt: "Interactive online pitch deck project image",
            title: "Interactive online pitch deck",
            link: "/templates/web-app-templates/interactive-online-pitch-deck/",
          },
          {
            image: onlineBankingImage,
            alt: "Online banking web app project image",
            title: "Online banking web app",
            link: "/templates/web-app-templates/online-banking-web-app/",
          },
        ]}
      />

      <TemplatesSection
        title="Tablet design templates"
        description="A collection of pre-made tablet UI design templates, created, and optimized with success in mind to kick off your own projects."
        linkPage="/templates/tablet-templates/"
        thumbnails={[
          {
            image: bankAppTabletImage,
            alt: "banking tablet app design template",
            title: "Banking tablet app",
            link: "/templates/tablet-templates/online-banking-tablet-app/",
          },
          {
            image: eCommerceTabletImage,
            alt: "e-shop app template for tablet template",
            title: "E-shop tablet app",
            link: "/templates/tablet-templates/e-commerce-tablet-app/",
          },
          {
            image: landoTabletImage,
            alt: "tablet app landing page design template",
            title: "Landing page for tablet",
            link: "/templates/tablet-templates/startup-web-page-tablet-format/",
          },
          {
            image: booktabImage,
            alt: "book reading tablet app design template",
            title: "Book reading tablet app",
            link: "/templates/tablet-templates/book-reading-tablet-app/",
          },
          {
            image: newsfeedtabImage,
            alt: "news feed tablet app design template",
            title: "News feed tablet app",
            link: "/templates/tablet-templates/news-feed-tablet-app/",
          },
          {
            image: learningtabImage,
            alt: "learning tablet app design template",
            title: "Learning tablet app",
            link: "/templates/tablet-templates/learning-tablet-app/",
          },
        ]}
      />

      <TemplatesSection
        title="UI components library"
        description="The building blocks for designing mobile apps, websites, and desktop software, explore our range of drag-and-drop UI components and streamline the way you design."
        linkPage="/templates/component-templates/"
        thumbnails={[
          {
            image: navImage,
            alt: "navigation ui components",
            title: "Navigation UI components",
            link: "/templates/component-templates/navigation/",
          },
          {
            image: socialImage,
            alt: "social ui components",
            title: "Social UI components",
            link: "/templates/component-templates/social/",
          },
          {
            image: listImage,
            alt: "list ui components",
            title: "List UI components",
            link: "/templates/component-templates/list/",
          },
          {
            image: formImage,
            alt: "form ui components",
            title: "Form UI components",
            link: "/templates/component-templates/form/",
          },
          {
            image: footerImage,
            alt: "footer ui components",
            title: "Footer UI components",
            link: "/templates/component-templates/footer/",
          },
          {
            image: blogImage,
            alt: "Content ui components",
            title: "Content UI components",
            link: "/templates/component-templates/content/",
          },
        ]}
      />

      <Content>
        <Shoutout
          headline="UI templates for non-designers"
          description="Bring your vision to life today!"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </Content>
    </Layout>
  );
};

export default Templates;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    comparisonScreensImageA: file(
      relativePath: { eq: "comparison/screens-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonScreensImageB: file(
      relativePath: { eq: "comparison/screens-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1008, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
